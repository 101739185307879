.icon-overlay {
  filter: invert(100%) sepia(35%) saturate(2%) hue-rotate(356deg)
    brightness(111%) contrast(101%) drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  -webkit-filter: invert(100%) sepia(35%) saturate(2%) hue-rotate(356deg)
    brightness(111%) contrast(101%) drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  opacity: 0.3;
}

.icon-overlay:hover {
  filter: invert(100%) sepia(35%) saturate(2%) hue-rotate(356deg)
    brightness(111%) contrast(101%) drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  -webkit-filter: invert(100%) sepia(35%) saturate(2%) hue-rotate(356deg)
    brightness(111%) contrast(101%) drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  opacity: 0.9;
}

.icon-shadow-white {
  -webkit-filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  opacity: 0.3;
}

.icon-shadow-white:hover {
  -webkit-filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  opacity: 0.9;
}
